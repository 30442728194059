import isMobile from "is-mobile";
import revive, { iddle } from "@/scripts/extra/revive"

// Islands
const islands = {
  ...import.meta.glob('@/scripts/sections/*.js'),
  ...import.meta.glob('@/scripts/templates/*.js'),
  ...import.meta.glob('@/scripts/templates/customers/*.js')
}

import '@/scripts/modules/article-main.js';
import '@/scripts/modules/sticky-atc-card.js';
import '@/scripts/modules/support-menu.js';
import '@/scripts/modules/video-control.js';

revive(islands)

async function loadLibraries() {
  await iddle()

  import('@/scripts/extra/load-libraries.js')
}

loadLibraries()

// eslint-disable-next-line no-prototype-builtins
if (!HTMLElement.prototype.hasOwnProperty("inert")) {
  import( /* @vite-ignore */ flu.chunks.polyfillInert);
}

if (isMobile({ tablet: true, featureDetect: true })) {
  add(document.body, "is-mobile");
}